<template>
  <v-dialog
    :value="true"
    transition="fade-transition"
    fullscreen
  >
    <div>
      <v-card
        :key="clickedDayData.date"
        v-on-clickaway="closeDialog"
        :style="positionDialog"
      >
        <v-card-title>
          <v-row>
            <v-col>
              <v-row class="d-flex align-center justify-space-between pl-2 pb-2">
                <div class="h6" v-text="$t('common.create')" />
                <v-btn icon color="black" @click="$emit('closeCreateDialog')">
                  <v-icon small color="primary">
                    close
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col class="d-flex flex-column justify-center align-start">
              <a
                class="subtitle-1 font-weight-medium mt-2"
                @click="$router.push({ name: 'calendar-create-event', params: { type: 'training', date: clickedDayData.date } })"
                v-text="$t('project.academy.calendar.training')"
              />
              <a
                class="subtitle-1 font-weight-medium"
                @click="$router.push({ name: 'calendar-create-event', params: { type: 'match', date: clickedDayData.date } })"
                v-text="$t('project.academy.calendar.match')"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'CalendarCreateDialog',
  mixins: [clickaway],
  props: {
    clickedDayData: {
      type: Object,
      default: () => {},
    },
    positionDialog: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeCreateDialog')
    },
  },
}
</script>
